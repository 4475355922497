.container-fluid {
    padding-top: 1vh;
    padding-bottom: 1vh;
}
.page-header {
    border: none;
}
.admin-footer {
    bottom: 0px !important;
    position: relative !important;
    width: 99% !important;
    background-color: #f1f1f1 !important;
    padding: 1.25rem 0;
    font-size: 14px;
}

.page .section-body:nth-child(2){
    background-color: #f1f1f1 !important;
    height: 100% !important;
    margin-top: 0px !important;
}
.page .section-body:nth-child(3){
    background-color: #f1f1f1 !important;
    height: 100% !important;
    margin-top: 0px !important;
}
/* .section-body .card {
	min-height: 62vh;
}  */

.spinner_main_div {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-search .p-inputtext {
    border-radius: 10px;
    width: 50%;
}

@media screen and (max-width: 768px) {
    .cash-memo-head {
        padding-left: 0;
        font-size: 14px !important;
    }

    .company-name {
        font-size: 20px;
    }

    .label-class {
        font-size: 14px !important;
    }

    .table-responsive .table th {
        font-size: 12px;
    }
}

@media screen and (max-width: 425px) {
    .company-name {
        font-size: 15px;
    }

    .flex-class {
        display: block !important;
    }

    .custom-search {
        padding-left: 0;
        display: flex;
    }

    .close-submit {
        text-align: center;
    }

    .card .card-body {
        padding-left: 3px;
        padding-right: 3px;
    }

    .card .card-body .common-fields {
        padding-left: 0;
        padding-right: 0;
    }

    .card .card-header {
        padding-left: 7.5px;
    }

    .card input {
        line-height: 1;
    }

    .custom-search {
        padding-right: 0;
    }

    .custom-search label {
        padding-left: 0 !important;
    }

    .custom-search .p-inputtext {
        width: 100%;
        line-height: 1;
    }
}