@import './assets/plugins/bootstrap/css/bootstrap.min.css';

@import './assets/css/main.css';

@import './assets/css/theme2.css';

@import './assets/css/custom.css';

@import './assets/plugins/charts-c3/c3.min.css';

/* @import './assets/plugins/summernote/dist/summernote.css'; */

@import './assets/plugins/jvectormap/jvectormap-2.0.3.css';

@import './assets/plugins/bootstrap-multiselect/bootstrap-multiselect.css';

@import './assets/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css';

@import './assets/plugins/fullcalendar/fullcalendar.min.css';

@import './assets/plugins/jquery-steps/jquery.steps.css';

.submit-btn {
    float: right;
    text-align: right;
}

.accordion-custome span {
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.button-status {
    border: none;
    width: 85px;
    justify-content: center;
    display: flex;
    align-items: center;
}
.autocomplete-dropdown {
    width: 50% !important; /* Ensure the dropdown takes full width */
  }

.button-status:hover {
    outline: none;
    border: none;
}

.table thead th {
    vertical-align: middle;
}

.w-cartoon {
    width: 133px;
}

.tag,
.btn-default,
.dispatch-table .btn {
    width: 85px;
    justify-content: center;
    height: 35px;
    border-radius: 0.2rem;
    font-size: 12px;
}

.add_btn {
    width: 85px;
}

.tag {
    display: flex;
    align-items: center;
}

.btn {
    font-size: 12px;
    height: 35px;
}

.btn-default {
    padding: 8px 18px !important;
}

.dispatch-table td:last-child {
    min-width: 110px;
}

/* .dispatch-table td{
    min-width: 360px;
} */

.account_heading {
    background-color: whitesmoke;
    padding: 10px 0;
    padding-left: 15px;
    margin-top: 25px;
    font-size: 17px;
    margin-bottom: 0;
}

.table td,
.table th {
    vertical-align: middle;
}

.actions-position {
    padding-right: 160px !important;
}

.btn.btn-default,
.btn-default1 {
    background-color: white;
    color: #50b12e;
    border-color: #50b12e;
}

.btn-primary {
    height: 35px;
}

.error {
    color: red;
    font-size: 13PX;
    font-weight: 500;
}

.required {
    color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.p-dropdown {
    height: 40px;
    border-color: #e8e9e9 !important;
    width: 100%;
}

.p-calendar {
    padding: 0;
}

.p-dropdown .p-dropdown-label {
    /* padding : 0; */
}

.website-template .bordered-row {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px;
    margin: 0 0 30px 0;
}

.action_btn_section {
    text-align: right;
    width: calc(100% - 7.5px);
    display: flex;
    justify-content: end;
}

.action_btn_section button {
    color: white;
    border: none;
    border-radius: 5px;
    background-color: #004583;
    font-size: 30px;
    /* padding: 15px; */
    height: 33px;
    width: 33px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.action_btn_section button:focus {
    outline: none;
}

.invalidDataError {
    color: red !important;
}

.p-multiselect {
    width: 100%;
    height: 40px !important;
}

.p-calendar {
    width: 100%;
    height: 40px;
}

.spinner_class {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000033;
    z-index: 99999;
    position: fixed;
    width: 100vw;
    overflow: hidden;
    top: 0;
}

.download-invoice {
    width: 7.5rem !important;
    padding: 8px 0 !important;
}

.deliver-css {
    width: 90px;
    height: 35px;
}

  .page-break {
    clear: both;
    page-break-before: always;
    page-break-inside: always;
    background-color: white;
    border-collapse: collapse;
    margin-top: 1000px !important;
  }

